import './App.css';
import React from 'react';
import { Component, useState, useEffect } from 'react';


class CookieConsentElement extends Component {
 
  constructor(props) {
    super(props)
    this.state = {
      cookieShow: true
    }
    this.handleClick = this.handleClick.bind(this)
    this.showCookiePopup = this.showCookiePopup.bind(this)
    this.removeCookiePopup = this.removeCookiePopup.bind(this)
  }

  handleClick() {
    this.setState(state => ({
      cookieShow: false
    }));
  localStorage.setItem('consent','true')
  }


  showCookiePopup() {
    return (
    <div className="d-flex justify-content-center container mt-5 overlay"  style={{ zIndex:1000, pointerEvents:'none' }}>
        <div className="row " >
		<div className="col-md-3" >
                </div>
            <div className="col-md-6 "  >
                <div className="d-flex flex-row justify-content-between align-items-center card cookie p-3" style={{ pointerEvents: 'auto', backgroundColor: '#1a252f' }}>
                    <div className="d-flex flex-row align-items-center">
                        <div className="ml-2 mr-2" style={{ textAlign:'justify' }}><span style={{ color:'white' }}>Tato webová stránka používá technické cookies za účelem zajištění funkčnosti webových stránek. </span><a data-bs-toggle="modal" href="#portfolioModalPrivacyPolicy">Zjistěte více</a></div>
                    </div>
		    <div></div>
		
            <div style={{paddingLeft:'20px'}}><button className="btn btn-primary" onClick={this.handleClick} type="button" >Rozumím!</button></div>
                  </div>                 
            </div>
	        <div className="col-md-3" >
                </div>
        </div>
    </div>
  )}

  removeCookiePopup() {
    return (
	null
  )}


  render(){
	return(<div>{ localStorage.getItem('consent') ? this.removeCookiePopup() : this.showCookiePopup() }</div>)
}
}

export default CookieConsentElement;